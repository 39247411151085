import axios from "axios";

export default {
  addBindRelate: (params) => {
    return axios.post("/a1/goods/bindrelate", params);
  },
  getBindRelate: (params) => {
    return axios.get("/a1/goods/bindrelate", { params: params });
  },
  getBindRelateDetail: (sku) => {
    return axios.get(`/a1/goodsRelate/${sku}`);
  },
};

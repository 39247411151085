import bindrelate from "@/api/admin/bindrelate";

export default {
  name: "less",
  data() {
    return {
      addBindFormWrap: false,
      bindForm: {
        skus: "",
        isbuy: 0,
      },
      bindData: [],
      bindDataPageInfo: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
      detailDataWrap: false,
      detailData: [],
      detailPageInfo: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getBindData();
  },
  methods: {
    submitBind() {
      let arr=[];
      this.bindForm.skus.split("\n").forEach(item => {
        if (item) {
          arr.push(item.trim());
        }
      });
      bindrelate.addBindRelate({ isbuy: this.bindForm.isbuy, skus: arr }).then(res => {
        this.$message.success("添加成功");
        this.addBindFormWrap=false;
        this.getBindData();
      });
    },
    getBindData() {
      bindrelate.getBindRelate({
        page: this.bindDataPageInfo.page,
        pagesize: this.bindDataPageInfo.pagesize,
      }).then(res => {
        this.bindData=res.data.data.data;
        this.bindDataPageInfo.total=res.data.data.total;
      });
    },
    getDetail(sku) {
      this.detailDataWrap=true;
      bindrelate.getBindRelateDetail(sku).then(res => {
        this.detailData=res.data.data;
      });
    },
    handleDelete(row) {
      let id=row.id;
      this.$axios.delete(`/a1/goodsRelate/${id}`).then(() => {
        this.$message.success("删除成功");
        this.getDetail(row.sku_base);
      });
    },
    //是否绑定购买选择器
    changeUp: function(callback, row, eventType) {
      let putData={
        is_buy: row.is_buy + "",
      };
      let id=row.id;
      this.$axios.put(`/a1/goodsRelate/${id}`, putData).then(() => {
        this.$message.success("修改成功");
      });
    },
    handleSizeChange(val) {
      this.bindDataPageInfo.page=1;
      this.bindDataPageInfo.pagesize=val;
      this.getBindData();
    },
    handleChangeCurrentPage(page) {
      this.bindDataPageInfo.page=page;
      this.getBindData();
    },
  },
};

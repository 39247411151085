<template>
  <div class="less">
    <div class="less-header">
      <el-button @click="addBindFormWrap = true">添加绑定</el-button>
      <el-dialog title="互相绑定条码" :visible.sync="addBindFormWrap" width="35%">
        <el-form ref="form" label-width="80px">
          <el-form-item label="购买绑定">
            <el-switch
              v-model="bindForm.isbuy"
              active-text="是"
              :active-value="1"
              inactive-text="否"
              :inactive-value="0"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="条码">
            <el-input type="textarea" rows="8" v-model="bindForm.skus"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
				  <el-button @click="addBindFormWrap = false">取 消</el-button>
				  <el-button type="primary" @click="submitBind">提 交</el-button>
			  </span>
      </el-dialog>
    </div>
    <div class="less-content">
      <div class="less-content-list">
        <el-table :data="bindData">
          <el-table-column type="index" label="id"></el-table-column>
          <el-table-column prop="sku_base" label="条码"></el-table-column>
          <el-table-column prop="base_price" label="操作" min-width="80">
            <template v-slot="{row}">
              <el-button type="primary" plain @click="getDetail(row.sku_base)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div ref="bindData-footer" class="bindData-footer" v-if="bindData.length > 0">
          <el-pagination :current-page="bindDataPageInfo.page"
                         :page-sizes="[10, 20, 50, 100, 500, 1000]"
                         :page-size="bindDataPageInfo.pagesize"
                         layout="total,sizes, prev, pager, next, jumper"
                         :total="bindDataPageInfo.total"
                         @current-change="handleChangeCurrentPage"
                         @size-change="handleSizeChange"
                         background />
        </div>
      </div>
      <div class="less-content-list-detail">

        <el-drawer
          title="绑定详情"
          :visible.sync="detailDataWrap"
          direction="rtl"
          size="45%">
<!--          <div>
            <el-form ref="form" label-width="80px" :inline="true">
              <el-form-item label="商品编号">
                <el-input type="textarea" :rows="5" v-model="addRenewItemno"></el-input>
              </el-form-item>
              <el-button type="primary" @click="submitRenewDetail">提 交</el-button>
            </el-form>
          </div>-->
          <div class="drawer-content">
            <el-table :data="detailData" height="650">
              <el-table-column type="index"  min-width="80"></el-table-column>
              <el-table-column prop="sku_base" label="主条码" min-width="100"></el-table-column>
              <el-table-column prop="sku_list" label="绑定条码" min-width="100"></el-table-column>
              <el-table-column prop="is_buy" label="绑定购买" min-width="100">
                <template v-slot="scope">
                  <el-switch
                    v-model="scope.row.is_buy"
                    inactive-text="不绑定"
                    :inactive-value="0"
                    active-text="绑定"
                    :active-value="1"
                    @change="changeUp($event, scope.row, 'new')"
                  >
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="" label="操作" min-width="80">
                <template v-slot="{row}">
                  <el-button type="primary" plain @click="handleDelete(row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-drawer>

      </div>
    </div>
  </div>
</template>

<script>
import less from "./js/index";

export default less
</script>

<style lang="scss" scoped>
@import "./css/index";
</style>
